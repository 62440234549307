@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face { 
  font-family: "viseby"; 
  src: url("../public/VisbyMedium.woff"); 
} 

/* width */
.accomadation::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.accomadation::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

/* Handle */
.accomadation::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  
}

/* Handle on hover */
.accomadation::-webkit-scrollbar-thumb:hover {
  background: #555;
}