.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.5rem;
    color: #505d68;
  }

  
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: transparent;
    border: 2px solid #505d687e;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #505d68;
    border: 2px solid #505d68;
  }

  .gradient-background {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 80.43%);
  }

  .bg-privacy-policy-banner {
    background-image: url('./images/terms_conditions_bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 17rem;
  }
  .MapContainer {
  margin-top: 10px;
  border-radius: 0.375rem; /* equivalent to rounded-md */
  outline: none;
  border: 1px solid #C2D1D9; /* equivalent to border-customGray */
}

.css-b62m3t-container{
  width: 100%
}



.eventsCard {
  transition: transform 0.3s ease; /* Adjust the duration and timing function as needed */
}

.eventsCard:hover {
  transform: scale(1.05);
}
.pricingCard:hover {
  transform: scale(1.05);
}

.blogContent {
  /* Default font size for all elements */
  font-size: 16px; /* Adjust this value as needed */

  /* Font size for specific elements */
  h1 {
    font-size: 2em; /* Example font size for h1 */
  }

  h2 {
    font-size: 1.8em; /* Example font size for h2 */
  }

  h3 {
    font-size: 1.6em; /* Example font size for h3 */
  }

  h4 {
    font-size: 1.4em; /* Example font size for h4 */
  }

  h5 {
    font-size: 1.2em; /* Example font size for h5 */
  }

  h6 {
    font-size: 1em; /* Example font size for h6 */
  }

  p {
    font-size: 1em; /* Example font size for paragraphs */
  }

  i {
    font-size: 1em; /* Example font size for italic text */
  }

  strong {
    font-size: 1em; /* Example font size for strong text */
  }

  ul {
    font-size: 1em; /* Example font size for unordered lists */
  }

  li {
    font-size: 1em; /* Example font size for list items */
  }

  /* Add more tags and their font sizes as needed */
}

.financing label,
.financing legend{
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.financing input::file-selector-button {
  font-size: 21px;
  font-weight: 400;
  /* line-height: 32.76px; */
  text-align: left;
  color: #000000;
  padding: 0.5em;
  border: none;
  border-radius: 86px;
  background: #8DC67B;
}

.financing .react-datepicker__month-select,
.financing .react-datepicker__year-select{
  background: #8DC67B;
  border-radius: 5px;
  padding: 3px;
}

.financing .dob-picker .react-datepicker__year-select::-webkit-scrollbar {
  display: none;
}
#yogaOffered{
  display: flex;
}
#yogaOffered option{
  /* background: black !important; */
  border-radius: 20px !important;
}

/* .financing .react-datepicker__year-select */

.financing input[type="checkbox"] {
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #D9D9D9;
  border-radius: 4px;
  border: 2px solid #ccc;
  position: relative;
  cursor: pointer;
}

.financing input[type="checkbox"]:checked {
  background-color: blue;
  border-color: blue;
}

.financing input[type="checkbox"]{
  padding: 7px;
}

.financing input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 10px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.financing input[type="checkbox"]:checked::before {
  opacity: 1;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 8px 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  display: block;
  padding: 4px 12px;
  color: black;
  background-color: #c2d1d957;
  text-decoration: none;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.pagination a:hover {
  background-color: #00000079;
  color: #fff;
}

.pagination .active a {
  background-color: #000000;
  color: #fff;
  pointer-events: none;
}

.pagination .disabled a {
  color: #000000 !important;
  pointer-events: none;
  opacity: 0.6;
}
